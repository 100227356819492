import {createDireccionEntrega, updateDireccionEntrega} from "../actions/DireccionEntregaActions";

require('es6-promise').polyfill();


var zonaEntregaUtil = {

    identificarLugar(direccionEntrega, idDireccionEntrega, zonasEntrega, dispatch, updateDireccionEntrega, createDireccionEntrega) {
        return new Promise((resolve, reject) => {
            if (direccionEntrega && direccionEntrega.numero !== "" && direccionEntrega.calle !== "" && direccionEntrega.localidad !== "") {
                // eslint-disable-next-line no-undef
                var geocoder = new google.maps.Geocoder;

                return geocoder.geocode({'address': direccionEntrega.calle + "+" + direccionEntrega.numero + ",+" + direccionEntrega.codPostal + "+" + direccionEntrega.localidad + ",+" + direccionEntrega.provincia + ""}, function (results, status) {
                    if (status !== 'OK') {
                        console.log('Geocoder failed due to: ' + status);
                        return;
                    }
                    console.log("GEOCODER ", results, direccionEntrega);


                    let cambio = {};
                    cambio.idZonaEntrega = null;
                    cambio.nomZonaEntrega = null;
                    let lat = results[0].geometry.location.lat();
                    let lng = results[0].geometry.location.lng();
                    //Modifico lat y lng
                    cambio.lat = lat;
                    cambio.lng = lng;

                    zonasEntrega.allIds.some((idZonaEntrega, i) => {
                        let zonaEntrega = zonasEntrega.byId.zonasEntrega[idZonaEntrega];

                        if (zonaEntrega.limitarPor === "region") {
                            //Armo coordenadas
                            let arrayZona = [];
                            zonaEntrega.cordenadas.split("\n").forEach((coordenadas) => {
                                let coord = coordenadas.split(",");
                                let punto = {};
                                punto.lat = parseFloat(coord[0]);
                                punto.lng = parseFloat(coord[1]);
                                arrayZona.push(punto);
                            });

                            if (zonaEntrega.cordenadas && zonaEntrega.estado === 1) {
                                //Valido zona
                                // eslint-disable-next-line no-undef
                                var bermudaTriangle = new google.maps.Polygon({paths: arrayZona});
                                // eslint-disable-next-line no-undef
                                let r = google.maps.geometry.poly.containsLocation(new google.maps.LatLng(lat, lng), bermudaTriangle);
                                if (r) {
                                    //Modifico zona
                                    cambio.idZonaEntrega = zonaEntrega.id;
                                    cambio.nomZonaEntrega = zonaEntrega.nomZonaEntrega;
                                    return true;
                                }
                                return false;
                            }
                        } else if (zonaEntrega.limitarPor === "codPostal") {
                            let esZona = zonaEntrega.codigosPostales.split(" ").some((codPostal) => {
                                return codPostal === direccionEntrega.codPostal;
                            })
                            if (esZona) {
                                //Modifico zona
                                cambio.idZonaEntrega = zonaEntrega.id;
                                cambio.nomZonaEntrega = zonaEntrega.nomZonaEntrega;
                                return true;
                            }
                            return false;
                        }
                    });

                    if (idDireccionEntrega) {
                        cambio.id = idDireccionEntrega;
                        let nuevo = {};
                        nuevo[idDireccionEntrega] = cambio;
                        dispatch(updateDireccionEntrega(nuevo));
                    } else {
                        dispatch(createDireccionEntrega(cambio));
                    }
                    return resolve();
                }.bind(this));
            } else return reject();
        });
    }
}

export default zonaEntregaUtil;